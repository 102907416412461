import { tokens } from "../tokens"

export const en = {
  [tokens.common.languageChanged]: "Language changed",
  [tokens.common.currency]: "$",
  [tokens.common.locale]: "en-US",
  [tokens.common.currency_code]: "USD",
  [tokens.nav.requests]: "Orders",
  [tokens.nav.concierge]: "Concierges",
  [tokens.nav.condominium]: "Condominiums",
  [tokens.nav.businessPartner]: "Business partners",
  [tokens.nav.orderType]: "Order type",
  [tokens.nav.event]: "Events",
  [tokens.nav.requestStatus]: "Order status",
  [tokens.nav.academy]: "Academy",
  [tokens.nav.account]: "Account",
  [tokens.nav.analytics]: "Analytics",
  [tokens.nav.auth]: "Auth",
  [tokens.nav.blog]: "Blog",
  [tokens.nav.browse]: "Browse",
  [tokens.nav.calendar]: "Calendar",
  [tokens.nav.chat]: "Chat",
  [tokens.nav.checkout]: "Checkout",
  [tokens.nav.concepts]: "Presto",
  [tokens.nav.contact]: "Contact",
  [tokens.nav.course]: "Course",
  [tokens.nav.create]: "Create",
  [tokens.nav.crypto]: "Crypto",
  [tokens.nav.customers]: "Customers",
  [tokens.nav.dashboard]: "Dashboard",
  [tokens.nav.details]: "Details",
  [tokens.nav.ecommerce]: "E-Commerce",
  [tokens.nav.edit]: "Edit",
  [tokens.nav.error]: "Error",
  [tokens.nav.feed]: "Feed",
  [tokens.nav.fileManager]: "File Manager",
  [tokens.nav.finance]: "Finance",
  [tokens.nav.fleet]: "Fleet",
  [tokens.nav.forgotPassword]: "Forgot Password",
  [tokens.nav.invoiceList]: "Invoices - Remove",
  [tokens.nav.paymentList]: "Payments",
  [tokens.nav.jobList]: "Job Listings",
  [tokens.nav.kanban]: "Kanban",
  [tokens.nav.list]: "Dashboard",
  [tokens.nav.login]: "Login",
  [tokens.nav.logistics]: "Logistics",
  [tokens.nav.mail]: "Mail",
  [tokens.nav.management]: "Management",
  [tokens.nav.orderList]: "Orders - Old",
  [tokens.nav.overview]: "Overview",
  [tokens.nav.pages]: "Pages",
  [tokens.nav.postCreate]: "Post Create",
  [tokens.nav.postDetails]: "Post Details",
  [tokens.nav.postList]: "Post List",
  [tokens.nav.pricing]: "Pricing",
  [tokens.nav.productList]: "Products",
  [tokens.nav.profile]: "Profile",
  [tokens.nav.register]: "Register",
  [tokens.nav.resetPassword]: "Reset Password",
  [tokens.nav.socialMedia]: "Social Media",
  [tokens.nav.verifyCode]: "Verify Code",

  // Languages
  [tokens.language.languageTitle]: "Language",
  [tokens.language.portuguese]: "Portuguese",
  [tokens.language.english]: "English",
  [tokens.language.german]: "German",
  [tokens.language.spanish]: "Spanish",

  // Pages
  [tokens.page.order]: "Orders",
  [tokens.page.orderType]: "Order types",
  [tokens.page.orderCreate]: "Create order",
  [tokens.page.orderTypeCreate]: "Create order type",
  [tokens.page.orderTypeEdit]: "Edit order type",
  [tokens.page.chat]: "Chat",
  [tokens.page.condominium]: "Concierge",
  [tokens.page.conciergeCreate]: "Create Concierge",
  [tokens.page.conciergeEdit]: "Edit Concierge",
  [tokens.page.condominium]: "Condominium",
  [tokens.page.condominiumCreate]: "Create Condominium",
  [tokens.page.condominiumEdit]: "Edit Condominium",
  [tokens.page.businessPartner]: "Business partner",
  [tokens.page.businessPartnerCreate]: "Create Business partner",
  [tokens.page.businessPartnerEdit]: "Edit Business partner",
  [tokens.page.businessPartnerService]: "Business partner services",
  [tokens.page.businessPartnerServiceCreate]: "Create Business partner service",
  [tokens.page.businessPartnerServiceEdit]: "Edit Business partner service",
  [tokens.page.event]: "Event",
  [tokens.page.customersEvent]: "Event customers list",
  [tokens.page.eventCreate]: "Create Event",
  [tokens.page.eventEdit]: "Edit Event",

  // Common Screen
  [tokens.commonScreen.sortBy]: "Sort by",
  [tokens.commonScreen.FilterBy]: "Filter by",
  [tokens.commonScreen.searchBy]: "Search by",
  [tokens.commonScreen.filters.sortByCreatedNew]: "Sort by (the newest)",
  [tokens.commonScreen.filters.sortByCreatedLast]: "Sort by (the oldest)",
  [tokens.commonScreen.filters.searchByDocumentNumber]: "document number",
  [tokens.commonScreen.filters.searchByRegistrationNumber]: "registration number",
  [tokens.commonScreen.filters.searchByOrderType]: "service provided",
  [tokens.commonScreen.filters.searchByEmail]: "email",
  [tokens.commonScreen.filters.searchByOrderType]: "Search by order type",
  [tokens.commonScreen.filters.FilterByOthers]: "None",
  [tokens.commonScreen.filters.searchByName]: "name",
  [tokens.commonScreen.filters.ByDataonScreen]: "based on the data on the screen",
  [tokens.commonScreen.table.header.actions]: "Actions",
  [tokens.commonScreen.table.header.brand]: "Brand",
  [tokens.commonScreen.table.footer.rowsPerPage]: "Rows per page",
  [tokens.commonScreen.maleGender]: "Male",
  [tokens.commonScreen.femaleGender]: "Female",
  [tokens.commonScreen.othersGender]: "Others",
  [tokens.commonScreen.marital_status.single]: "Single",
  [tokens.commonScreen.marital_status.married]: "Married",
  [tokens.commonScreen.marital_status.widower]: "Widower",
  [tokens.commonScreen.marital_status.divorced]: "Divorced",
  [tokens.commonScreen.validation.invalid_field]: "Invalid field!",
  [tokens.commonScreen.validation.required_field]: "Required field!",
  [tokens.commonScreen.idNumber]: "Identity number",
  [tokens.commonScreen.socialSecuityNumber]: "Social security number",
  [tokens.commonScreen.driversLicence]: "Drivers licence",
  [tokens.commonScreen.documentNumber]: "Employer Number",
  [tokens.commonScreen.yes]: "Yes",
  [tokens.commonScreen.no]: "No",
  [tokens.commonScreen.active]: "Active",
  [tokens.commonScreen.disabled]: "Disabled",
  [tokens.commonScreen.requiredFiled]: "is a required field",
  [tokens.commonScreen.actions.delete]: "Delete",
  [tokens.commonScreen.actions.edit]: "Edit",
  [tokens.commonScreen.rollback_title]: "Go back",
  [tokens.commonScreen.btn_cancel]: "Cancel",
  [tokens.commonScreen.createForm.brand]: "Brand",

  //profile
  [tokens.profileScreen.main_title_profile]: "Basic details",
  [tokens.profileScreen.btn_create_profile]: "Create User",
  [tokens.profileScreen.table_main.header.picture]: "User Name",
  [tokens.profileScreen.table_main.header.email]: "E-mail",
  [tokens.profileScreen.table_main.header.usertype]: "User Type",
  [tokens.profileScreen.table_main.header.status]: "Status",
  [tokens.profileScreen.table_main.header.lastLogin]: "Last Login",
  [tokens.profileScreen.table_main.header.createAt]: "Create at",
  [tokens.profileScreen.table_main.filters.name]: "Name by",
  [tokens.profileScreen.table_main.filters.status]: "Status",
  [tokens.profileScreen.table_main.filters.empty_state.filter_usertype]: "No filters applied",
  [tokens.profileScreen.table_main.filters.empty_state.text_field]: "Search by user name",

  [tokens.profileScreen.table_main.filters.sort_by_date_new]: "Create at (newest)",
  [tokens.profileScreen.table_main.filters.sort_by_date_last]: "Create at (oldest)",
  [tokens.profileScreen.table_main.filters.sort_by_user_active]: "Sort by user active",
  [tokens.profileScreen.table_main.filters.sort_by_usertype]: "Sort by userType",
  //user
  [tokens.userScreen.main_title_user]: "Users",
  [tokens.userScreen.btn_create_user]: "Create User",
  [tokens.userScreen.table_main.header.name]: "User Name",
  [tokens.userScreen.table_main.header.email]: "E-mail",
  [tokens.userScreen.table_main.filters.search_user]: "Search user for ",
  [tokens.userScreen.table_main.header.usertype]: "User Type",
  [tokens.userScreen.table_main.header.status]: "Status",
  [tokens.userScreen.table_main.header.lastLogin]: "Last Login",
  [tokens.userScreen.table_main.header.createAt]: "Create at",
  [tokens.userScreen.table_main.filters.name]: "Name by",
  [tokens.userScreen.table_main.filters.status]: "Status",
  [tokens.userScreen.table_main.filters.empty_state.filter_usertype]: "No filters applied",
  [tokens.userScreen.table_main.filters.empty_state.text_field]: "Search by user name",
  [tokens.userScreen.table_main.filters.sort_by_date_new]: "Create at (newest)",
  [tokens.userScreen.table_main.filters.sort_by_date_last]: "Create at (oldest)",
  [tokens.userScreen.table_main.filters.sort_by_user_active]: "Sort by user active",
  [tokens.userScreen.table_main.filters.sort_by_usertype]: "Sort by userType",
  [tokens.userScreen.form.title_label_detail]: "User details",
  [tokens.userScreen.form.user_title_label_edit]: "User edit",
  [tokens.userScreen.form.user_title_label_create]: "List of User",
  [tokens.userScreen.form.user_id_label]: "ID",
  [tokens.userScreen.form.request_number_label]: "User number",
  [tokens.userScreen.form.request_type_label]: "User type",
  [tokens.userScreen.form.request_status_label]: "Status",
  [tokens.userScreen.form.user_label]: "User",
  [tokens.userScreen.form.user_name_label]: "User name",
  [tokens.userScreen.form.user_type_label]: "User Type",
  [tokens.userScreen.form.user_gender_label]: "Gender",
  [tokens.userScreen.form.user_welcomeNotification_label]: "Notification Welcome",
  [tokens.userScreen.form.user_phone_label]: "Phone:(+XX)XX-XXXXX-XXX)",
  [tokens.userScreen.form.user_email_label]: "User email",
  [tokens.userScreen.form.user_document_label]: "Document number",
  [tokens.userScreen.form.total_amount_label]: "Total amount",
  [tokens.userScreen.form.user_last_login_label]: "Last Login",
  [tokens.userScreen.form.btn_edit_label]: "Edit order",
  [tokens.userScreen.form.btn_approve]: "Approve",
  [tokens.userScreen.form.btn_reject]: "Reject",
  [tokens.userScreen.form.btn_close]: "Close",
  [tokens.userScreen.form.btn_chat]: "Chat",
  [tokens.userScreen.form.btn_save]: "Save changes",
  [tokens.userScreen.form.btn_cancel]: "Cancel",
  [tokens.userScreen.form.createForm.create_title]: "New order",
  [tokens.userScreen.form.createForm.btn_create_order]: "Create order",
  [tokens.userScreen.table_main.filters.sort_by_date_new]: "Create at (newest)",
  [tokens.userScreen.table_main.filters.sort_by_date_last]: "Create at (oldest)",
  [tokens.userScreen.form.createForm.sucess_message]: "Order created with success",
  [tokens.userScreen.form.createForm.error_message]: "Something went wrong creating order!",

  [tokens.userScreen.table_main.filters.filter_by_none]: "None",
  [tokens.userScreen.table_main.filters.filter_by_firstName]: "FirstName",
  [tokens.userScreen.table_main.filters.filter_by_lastName]: "LastName",
  [tokens.userScreen.table_main.filters.filter_by_phoneNumber]: "PhoneNumber",
  [tokens.userScreen.table_main.filters.filter_by_customer_email]: "E-mail",

  // Request screen
  [tokens.requestScreen.main_title]: "Orders",

  [tokens.requestScreen.btn_create_request]: "Create Order",
  [tokens.requestScreen.table_main.header.number]: "Order number",
  [tokens.requestScreen.table_main.header.client]: "Customer",
  [tokens.requestScreen.table_main.header.amount]: "Total amount",
  [tokens.requestScreen.table_main.header.paymentStatus]: "Payment status",
  [tokens.requestScreen.table_main.header.orderPaymentDate]: "Payment date",
  [tokens.requestScreen.table_main.header.orderType]: "Type",
  [tokens.requestScreen.table_main.header.orderElapsed]: "Elapsed days",
  [tokens.requestScreen.table_main.header.concierge]: "Concierge",
  [tokens.requestScreen.table_main.header.createAt]: "Create at",
  [tokens.requestScreen.table_main.filters.order]: "Order by",
  [tokens.requestScreen.table_main.filters.status]: "Status",
  [tokens.requestScreen.table_main.filters.empty_state.filter_category]: "No filters applied",
  [tokens.requestScreen.table_main.filters.empty_state.text_field]: "Search by customer name",
  [tokens.requestScreen.table_main.filters.empty_state.orderId_filter]: "Order number",
  [tokens.requestScreen.form.title_label_detail]: "Order details",
  [tokens.requestScreen.form.title_label_edit]: "Order edit",
  [tokens.requestScreen.form.id_label]: "ID",
  [tokens.requestScreen.form.request_number_label]: "Order number",
  [tokens.requestScreen.form.request_type_label]: "Order type",
  [tokens.requestScreen.form.request_businessPartner_label]: "Business Parter",
  [tokens.requestScreen.form.request_status_label]: "Status",
  [tokens.requestScreen.form.request_payment_status_label]: "Payment status",
  [tokens.requestScreen.form.customer_label]: "Customer",
  [tokens.requestScreen.form.customer_name_label]: "Customer name",
  [tokens.requestScreen.form.customer_phone_label]: "Customer phone",
  [tokens.requestScreen.form.customer_email_label]: "Customer email",
  [tokens.requestScreen.form.customer_document_label]: "Document number",
  [tokens.requestScreen.form.total_amount_label]: "Total amount",
  [tokens.requestScreen.form.request_date_label]: "Request date",
  [tokens.requestScreen.form.closing_date_label]: "Closing date",
  [tokens.requestScreen.form.payment_label]: "Payment info",
  [tokens.requestScreen.form.payment_date_label]: "Payment date",
  [tokens.requestScreen.form.payment_type_label]: "Payment type",
  [tokens.requestScreen.form.aditional_customer_label]: "Customer aditional details",
  [tokens.requestScreen.form.concierge_label]: "Concierge",
  [tokens.requestScreen.form.order_notes_label]: "Order notes",
  [tokens.requestScreen.form.concierge_name_label]: "Concierge",
  [tokens.requestScreen.form.concierge_email_label]: "Email",
  [tokens.requestScreen.form.concierge_phone_label]: "Phone",
  [tokens.requestScreen.form.concierge_identification_label]: "RA",
  [tokens.requestScreen.form.btn_edit_label]: "Edit order",
  [tokens.requestScreen.form.btn_approve]: "Approve",
  [tokens.requestScreen.form.btn_reject]: "Reject",
  [tokens.requestScreen.form.btn_close]: "Close",
  [tokens.requestScreen.form.btn_chat]: "Chat",
  [tokens.requestScreen.form.btn_save]: "Save changes",
  [tokens.requestScreen.form.btn_cancel]: "Cancel",
  [tokens.requestScreen.form.btn_complete_by_pix]: "Complete order with pix payment",
  [tokens.requestScreen.form.createForm.create_title]: "New order",
  [tokens.requestScreen.form.createForm.btn_create_order]: "Create order",
  [tokens.requestScreen.table_main.filters.sort_by_date_new]: "Create at (newest)",
  [tokens.requestScreen.table_main.filters.sort_by_date_last]: "Create at (oldest)",
  [tokens.requestScreen.table_main.filters.sort_by_total_amount_max]: "Total amount (highest)",
  [tokens.requestScreen.table_main.filters.sort_by_total_amount_min]: "Total amount (lowest)",
  [tokens.requestScreen.form.createForm.sucess_message]: "Order created with success",
  [tokens.requestScreen.form.createForm.error_message]: "Something went wrong creating order!",
  [tokens.requestScreen.form.updateFrom.sucess_message]: "Order updated with success",
  [tokens.requestScreen.form.updateFrom.error_message]: "Something went wrong updating order!",
  [tokens.requestScreen.form.default_grouped_condominium_name]: "Condominium not configured",
  [tokens.requestScreen.form.customer_default_select_option]: "Select a customer",
  [tokens.requestScreen.form.condominium_label]: "Customer condominium",
  [tokens.requestScreen.form.is_recurring_label]: "Recurring order",
  [tokens.requestScreen.form.is_recurring_description_label]:
    "Informs whether the order being created is a recurring order.",
  [tokens.requestScreen.form.businessPartner_label]: "Supplier",
  [tokens.requestScreen.form.businessPartnerService_label]: "Service",

  // Chat
  [tokens.requestScreen.form.chat.btn_see_order]: "See order",
  [tokens.requestScreen.form.chat.last_active]: "Last active",
  [tokens.requestScreen.form.chat.send_message]: "Leave a message",
  [tokens.requestScreen.form.chat.me]: "Me",
  [tokens.requestScreen.form.chat.message.error_attach_file]:
    "Invalid file! Check the format and/or size of maximum 10MB",
  [tokens.requestScreen.form.chat.message.error_upload_file]:
    "Error when trying to upload the file: ",
  [tokens.requestScreen.form.chat.message.error_download_file]:
    "Error when trying to download file, check your network and try again later.",

  // Concierge Screem
  [tokens.conciergeScreen.main_title_concierge]: "Concierge",
  [tokens.conciergeScreen.tab_option_all_label]: "All",
  [tokens.conciergeScreen.tab_option_active_label]: "Active",
  [tokens.conciergeScreen.tab_option_inactive_label]: "Inactive",

  [tokens.conciergeScreen.table_main.filters.sort_by_name_asc]: "Name (Asc)",
  [tokens.conciergeScreen.table_main.filters.sort_by_name_desc]: "Name (Desc)",
  [tokens.conciergeScreen.table_main.filters.search_concierge]: "Search concierge by ",

  [tokens.conciergeScreen.table_main.header.id]: "Id",
  [tokens.conciergeScreen.table_main.header.name]: "Name",
  [tokens.conciergeScreen.table_main.header.cpfDocument]: "Document number",
  [tokens.conciergeScreen.table_main.header.rgDocument]: "ID",
  [tokens.conciergeScreen.table_main.header.email]: "Email",
  [tokens.conciergeScreen.table_main.header.phone]: "Phone",
  [tokens.conciergeScreen.table_main.header.celphone]: "Cell phone",
  [tokens.conciergeScreen.table_main.header.isActive]: "Active",
  [tokens.conciergeScreen.table_main.row.rowDocumentTitle]: "Documents",
  [tokens.conciergeScreen.table_main.row.rowContactTitle]: "Contacts",
  [tokens.conciergeScreen.btn_create_concierge]: "Create Concierge",
  [tokens.conciergeScreen.form.createForm.create_title]: "New Concierge",
  [tokens.conciergeScreen.form.editForm.edit_title]: "Update Concierge",
  [tokens.conciergeScreen.form.createForm.personalInformation]: "Personal data",
  [tokens.conciergeScreen.form.createForm.contactIntomation]: "Contact details",
  [tokens.conciergeScreen.form.createForm.conciergeNameLabel]: "Full name",
  [tokens.conciergeScreen.form.createForm.conciergeDocumentNumberLabel]: "Document number",
  [tokens.conciergeScreen.form.createForm.conciergeIDLabel]: "ID",
  [tokens.conciergeScreen.form.createForm.conciergeGenderLabel]: "Gender",
  [tokens.conciergeScreen.form.createForm.conciergeCellphoneLabel]: "Cell phone",
  [tokens.conciergeScreen.form.createForm.conciergeEmailLabel]: "Email",
  [tokens.conciergeScreen.form.createForm.conciergePhoneLabel]: "Phone",
  [tokens.conciergeScreen.form.createForm.isActiveLabel]: "Active record",
  [tokens.conciergeScreen.form.createForm.btnCreateConcierge]: "Create concierge",
  [tokens.conciergeScreen.form.editForm.btnEditConcierge]: "Update concierge",
  [tokens.conciergeScreen.form.createForm.conciergeBreadcrumbsNavigationList]: "Concierges list",
  [tokens.conciergeScreen.form.createForm.conciergeBreadcrumbsNavigationEdit]: "Concierge edit",
  [tokens.conciergeScreen.form.sucess_message]: "Success during data persistence",
  [tokens.conciergeScreen.form.error_message]: "An error occurred while persisting the data",

  // Order Type Screen
  [tokens.orderTypeScreen.main_title]: "Order type",
  [tokens.orderTypeScreen.table_main.header.id]: "Id",

  [tokens.orderTypeScreen.table_main.filters.isInactive]: "Display only inactive records",

  [tokens.orderTypeScreen.table_main.filters.sort_by_order_type_asc]: "Order type (Asc)",
  [tokens.orderTypeScreen.table_main.filters.sort_by_order_type_desc]: "Order type (Desc)",

  [tokens.orderTypeScreen.table_main.header.orderType]: "Order type",
  [tokens.orderTypeScreen.table_main.header.createdAt]: "Created at",
  [tokens.orderTypeScreen.btn_create_orderType]: "Create order type",
  [tokens.orderTypeScreen.form.createForm.create_title]: "New order type",
  [tokens.orderTypeScreen.form.editForm.edit_title]: "Update order type",
  [tokens.orderTypeScreen.form.createForm.orderType]: "Order type",
  [tokens.orderTypeScreen.form.createForm.orderTypeDetails]: "Order type details",
  [tokens.orderTypeScreen.form.createForm.shortDescription]: "Short description",
  [tokens.orderTypeScreen.form.createForm.fullDescription]: "Full description",
  [tokens.orderTypeScreen.form.createForm.isInactiveLabel]: "Inactive",
  [tokens.orderTypeScreen.form.createForm.btnCreateOrderType]: "Create order type",
  [tokens.orderTypeScreen.form.createForm.btnCancelOrderType]: "Cancel",
  [tokens.orderTypeScreen.form.editForm.btnEditOrderType]: "Update order type",
  [tokens.orderTypeScreen.form.createForm.orderTypeBreadcrumbsNavigationList]: "Order types list",
  [tokens.orderTypeScreen.form.createForm.orderTypeBreadcrumbsNavigationEdit]: "Order type edit",

  [tokens.orderTypeScreen.form.sucess_message]: "Order type created/updated successfully",
  [tokens.orderTypeScreen.form.error_message]:
    "An error occurred while creating the new order type",

  // Condominium Screem
  [tokens.condominiumScreen.main_title]: "Condominium",
  [tokens.condominiumScreen.table_main.header.id]: "Id",
  [tokens.condominiumScreen.table_main.header.name]: "Name",
  [tokens.condominiumScreen.table_main.header.createdAt]: "Created at",
  [tokens.condominiumScreen.table_main.header.cnpj]: "Registration number",
  [tokens.condominiumScreen.table_main.header.managerName]: "Manager Name",

  [tokens.condominiumScreen.table_main.filters.sort_by_name_asc_con]: "Name (Asc)",
  [tokens.condominiumScreen.table_main.filters.sort_by_name_desc_con]: "Name (Desc)",
  [tokens.condominiumScreen.table_main.filters.search_condominium]: "Search condominium by ",

  [tokens.condominiumScreen.btn_create_condominium]: "Create Condominium",
  [tokens.condominiumScreen.form.createForm.create_title]: "New Concierge",
  [tokens.condominiumScreen.form.editForm.edit_title]: "Update Concierge",
  [tokens.condominiumScreen.form.createForm.condominiumInformation]: "Details",
  [tokens.condominiumScreen.form.createForm.condominiumAddress]: "Address",
  [tokens.condominiumScreen.form.createForm.condominiumNameLabel]: "Name",
  [tokens.condominiumScreen.form.createForm.notes_label]: "Condominium notes",

  [tokens.condominiumScreen.form.createForm.cnpjDocumentLabel]: "Registration number",
  [tokens.condominiumScreen.form.createForm.managernameLabel]: "Manager's name",
  [tokens.condominiumScreen.form.createForm.referralCode]: "Referral code",
  [tokens.condominiumScreen.form.createForm.managerdocumentLabel]: "Document number",
  [tokens.condominiumScreen.form.createForm.managerdocumenttypeLabel]: "Document type",
  [tokens.condominiumScreen.form.createForm.address.streetNameLabel]: "Street",
  [tokens.condominiumScreen.form.createForm.address.neighborhoodLabel]: "Neighborhood",
  [tokens.condominiumScreen.form.createForm.address.complimentaryInformationLabel]:
    "Complimentary Information",
  [tokens.condominiumScreen.form.createForm.address.numberLabel]: "Number",
  [tokens.condominiumScreen.form.createForm.address.zipCodeLabel]: "Zipcode",
  [tokens.condominiumScreen.form.createForm.address.cityLabel]: "City",
  [tokens.condominiumScreen.form.createForm.address.stateLabel]: "State",
  [tokens.condominiumScreen.form.createForm.address.countryLabel]: "Country",

  [tokens.condominiumScreen.form.createForm.btnCreateCondominium]: "Create condominium",
  [tokens.condominiumScreen.form.createForm.btnCancelCondominium]: "Cancel",
  [tokens.condominiumScreen.form.createForm.condominiumBreadcrumbsNavigationList]:
    "Condominium list",
  [tokens.condominiumScreen.form.createForm.condominiumBreadcrumbsNavigationEdit]:
    "Edit condominium",
  [tokens.condominiumScreen.form.editForm.btnEditCondominium]: "Update condominium",
  [tokens.condominiumScreen.form.sucess_message]: "Condominium created/updated successfully",
  [tokens.condominiumScreen.form.error_message]: "An error occurred while persisting the data",

  // Business Partner Screem
  [tokens.businessPartnerScreen.main_title]: "Business partner",
  [tokens.businessPartnerScreen.table_main.header.id]: "Id",
  [tokens.businessPartnerScreen.table_main.header.name]: "Name",
  [tokens.businessPartnerScreen.table_main.header.homeService]: "Home Service",
  [tokens.businessPartnerScreen.table_main.header.createdAt]: "Created at",
  [tokens.businessPartnerScreen.table_main.header.cnpj]: "Registration number",
  [tokens.businessPartnerScreen.table_main.header.serviceProvided]: "Service provided",
  [tokens.businessPartnerScreen.table_main.header.amoutProvidedServices]: "Amount services",
  [tokens.businessPartnerScreen.table_main.filters.homeService]: "Home Service",
  [tokens.businessPartnerScreen.table_main.filters.search_businessPartner]:
    "Search business partner by ",

  [tokens.businessPartnerScreen.table_main.filters.sort_by_name_asc_bp]: "Name (Asc)",
  [tokens.businessPartnerScreen.table_main.filters.sort_by_name_desc_bp]: "Name (Desc)",

  [tokens.businessPartnerScreen.btn_create_businessPartner]: "Create business partner",
  [tokens.businessPartnerScreen.form.createForm.create_title]: "New business partner",
  [tokens.businessPartnerScreen.form.editForm.edit_title]: "Update business partner",
  [tokens.businessPartnerScreen.form.notes_label]: "Business partner notes",
  [tokens.businessPartnerScreen.form.createForm.businessPartnerNameLabel]: "Business partner",
  [tokens.businessPartnerScreen.form.createForm.businessPartnerInformation]: "Informartions",
  [tokens.businessPartnerScreen.form.createForm.businessPartnerAddress]: "Adress",
  [tokens.businessPartnerScreen.form.createForm.businessPartnerContact]: "Contact",
  [tokens.businessPartnerScreen.form.createForm.cnpjDocumentLabel]: "Registration number",
  [tokens.businessPartnerScreen.form.createForm.address.streetNameLabel]: "Street",
  [tokens.businessPartnerScreen.form.createForm.address.neighborhoodLabel]: "Neighborhood",
  [tokens.businessPartnerScreen.form.createForm.address.complimentaryInformationLabel]:
    "Complimentary Information",
  [tokens.businessPartnerScreen.form.createForm.address.numberLabel]: "Number",
  [tokens.businessPartnerScreen.form.createForm.address.zipCodeLabel]: "Zip code",
  [tokens.businessPartnerScreen.form.createForm.address.cityLabel]: "City",
  [tokens.businessPartnerScreen.form.createForm.address.stateLabel]: "State",
  [tokens.businessPartnerScreen.form.createForm.address.countryLabel]: "Country",
  [tokens.businessPartnerScreen.form.createForm.contact.phoneNumber]: "Phone number",
  [tokens.businessPartnerScreen.form.createForm.contact.email]: "Email",
  [tokens.businessPartnerScreen.form.createForm.contact.talkTo]: "Talk to",
  [tokens.businessPartnerScreen.form.createForm.homeService]: "Home service",
  [tokens.businessPartnerScreen.form.createForm.pixkey]: "Pix key",
  [tokens.businessPartnerScreen.form.createForm.serviceProvided]: "Service provided",
  [tokens.businessPartnerScreen.form.createForm.btnCreateBusinessPartner]:
    "Create business partner",
  [tokens.businessPartnerScreen.form.createForm.btnCancelBusinessPartner]: "Cancel",
  [tokens.businessPartnerScreen.form.createForm.bpBreadcrumbsNavigationList]:
    "Business partner list",
  [tokens.businessPartnerScreen.form.createForm.bpBreadcrumbsNavigationEdit]:
    "Edit business partner",

  [tokens.businessPartnerScreen.form.editForm.btnEditBusinessPartner]: "Business business partner",
  [tokens.businessPartnerScreen.form.sucess_message]:
    "Business partner created/updated successfully",
  [tokens.businessPartnerScreen.form.error_message]: "An error occurred while persisting the data",

  // Business Partner Screem Service
  [tokens.businessPartnerServiceScreen.main_title]: "Services",
  [tokens.businessPartnerServiceScreen.table_main.header.id]: "Id",
  [tokens.businessPartnerServiceScreen.table_main.header.service]: "Service",
  [tokens.businessPartnerServiceScreen.table_main.header.description]: "Description",
  [tokens.businessPartnerServiceScreen.table_main.header.takenRate]: "Taken rate",
  [tokens.businessPartnerServiceScreen.table_main.header.createdAt]: "Created at",
  [tokens.businessPartnerServiceScreen.btn_create_businessPartnerServiceScreen]: "Create service",
  [tokens.businessPartnerServiceScreen.form.createForm.create_title]: "New service",
  [tokens.businessPartnerServiceScreen.form.editForm.edit_title]: "Update service",
  [tokens.businessPartnerServiceScreen.form.createForm.service]: "Service",
  [tokens.businessPartnerServiceScreen.form.createForm.description]: "Description",
  [tokens.businessPartnerServiceScreen.form.createForm.takenRate]: "Taken rate",
  [tokens.businessPartnerServiceScreen.form.createForm.createdAt]: "Created at",
  [tokens.businessPartnerServiceScreen.form.createForm.updatedAt]: "Update at",

  [tokens.businessPartnerServiceScreen.form.createForm.bpsBreadcrumbsNavigationList]:
    "Services list",
  [tokens.businessPartnerServiceScreen.form.createForm.bpsBreadcrumbsNavigationEdit]:
    "Edit service",

  [tokens.businessPartnerServiceScreen.form.createForm.btnCreateBusinessPartnerService]:
    "Create business partner service",
  [tokens.businessPartnerServiceScreen.form.createForm.btnCancelBusinessPartnerService]: "Cancel",
  [tokens.businessPartnerServiceScreen.form.editForm.btnEditBusinessPartnerService]:
    "Create business partner service",
  [tokens.businessPartnerServiceScreen.form.sucess_message]:
    "Business partner service created/updated successfully",
  [tokens.businessPartnerServiceScreen.form.error_message]:
    "An error occurred while persisting the data",
  [tokens.businessPartnerServiceScreen.businessPartnerServiceToolTip]: "Business partner services",

  // Payment Screem Service
  [tokens.paymentReportScreen.main_title]: "Payments",
  [tokens.paymentReportScreen.btn_filter_paymentReportScreen]: "Filters",
  [tokens.paymentReportScreen.btn_closePdf_paymentReportScreen]: "Close",
  [tokens.paymentReportScreen.btn_preview_paymentReportScreen]: "Preview",
  [tokens.paymentReportScreen.btn_download_paymentReportScreen]: "Download",
  [tokens.paymentReportScreen.placeHolder_filter_orderId_paymentReportScreen]: "Order number",
  [tokens.paymentReportScreen.label_filter_fromCustomer_paymentReportScreen]: "From Customer",
  [tokens.paymentReportScreen.label_filter_PaidOnly_paymentReportScreen]: "Paid only",
  [tokens.paymentReportScreen.label_filter_GrooupByStatus_paymentReportScreen]: "Group by status",
  [tokens.paymentReportScreen.pdf_name]: "Order-",

  [tokens.paymentReportScreen.table_main.header.orderDate]: "Order date",
  [tokens.paymentReportScreen.table_main.header.paymentDate]: "Payment date",
  [tokens.paymentReportScreen.label_filter_orderDateFrom_paymentReportScreen]: "From",
  [tokens.paymentReportScreen.label_filter_orderDateTo_paymentReportScreen]: "To",
  [tokens.paymentReportScreen.status.pending]: "Pending",
  [tokens.paymentReportScreen.status.paid]: "Paid",
  [tokens.paymentReportScreen.status.canceled]: "Canceled",
  [tokens.paymentReportScreen.status.refund]: "Refund",
  [tokens.paymentReportScreen.label_summary_from_paymentReportScreen]: "from",
  [tokens.paymentReportScreen.label_summary_payments_paymentReportScreen]: "payments",

  [tokens.paymentReportScreen.details.orderDate]: "Order date",
  [tokens.paymentReportScreen.details.paymentDate]: "Payment date",
  [tokens.paymentReportScreen.details.orderNumber]: "Order number",
  [tokens.paymentReportScreen.details.orderType]: "Order type",
  [tokens.paymentReportScreen.details.paidBy]: "Paid by",
  [tokens.paymentReportScreen.details.assistedBy]: "Assisted by",
  [tokens.paymentReportScreen.details.description]: "Description",
  [tokens.paymentReportScreen.details.qty]: "Qty",
  [tokens.paymentReportScreen.details.price]: "Price",
  [tokens.paymentReportScreen.details.takenRate]: "Taken rate Presto",
  [tokens.paymentReportScreen.details.total]: "Total",
  [tokens.paymentReportScreen.details.subTotal]: "Sub total",
  [tokens.paymentReportScreen.details.paymentType.type]: "Payment type",

  // Event Screem
  [tokens.eventScreen.main_title]: "Event",
  [tokens.eventScreen.table_main.header.id]: "Id",
  [tokens.eventScreen.table_main.header.shortEventDescription]: "Short description",

  [tokens.eventScreen.table_main.filters.sort_by_name_asc_ev]: "Description (Asc)",
  [tokens.eventScreen.table_main.filters.sort_by_name_desc_ev]: "Description (Desc)",

  [tokens.eventScreen.eventCustomersToolTip]: "Customers list",
  [tokens.eventScreen.tab_option_free_label]: "Free event",
  [tokens.eventScreen.tab_option_paid_label]: "Paid event",
  [tokens.eventScreen.table_main.header.eventDate]: "Event Date",
  [tokens.eventScreen.table_main.header.eventValue]: "Value",
  [tokens.eventScreen.table_main.header.createdAt]: "Created At",
  [tokens.eventScreen.btn_create_event]: "Create Event",
  [tokens.eventScreen.table_main.header.isFree]: "Free",
  [tokens.eventScreen.table_main.header.maximumCustomers]: "Customers limit",
  [tokens.eventScreen.table_main.header.amountOfCustomer]: "Registered customers",
  [tokens.eventScreen.form.createForm.create_title]: "New Event",
  [tokens.eventScreen.form.editForm.edit_title]: "Update Event",
  [tokens.eventScreen.form.createForm.eventInformation]: "Details",
  [tokens.eventScreen.form.createForm.eventAddress]: "Address",

  [tokens.eventScreen.form.createForm.shortEventDescriptionLabel]: "Short description",
  [tokens.eventScreen.form.createForm.fullEventDescriptionLabel]: "Details",
  [tokens.eventScreen.form.createForm.eventDateLabel]: "Date",
  [tokens.eventScreen.form.createForm.eventValueLabel]: "Value",
  [tokens.eventScreen.form.createForm.isFreeLabel]: "Free event",
  [tokens.eventScreen.form.createForm.maximumCustomers]: "Customer limit",
  [tokens.eventScreen.form.createForm.address.streetNameLabel]: "Street",
  [tokens.eventScreen.form.createForm.address.neighborhoodLabel]: "Neighborhood",
  [tokens.eventScreen.form.createForm.address.complimentaryInformationLabel]:
    "Complimentary Information",
  [tokens.eventScreen.form.createForm.address.numberLabel]: "Number",
  [tokens.eventScreen.form.createForm.address.zipCodeLabel]: "Zipcode",
  [tokens.eventScreen.form.createForm.address.cityLabel]: "City",
  [tokens.eventScreen.form.createForm.address.stateLabel]: "State",
  [tokens.eventScreen.form.createForm.address.countryLabel]: "Country",
  [tokens.eventScreen.form.createForm.eventBreadcrumbsNavigationList]: "Event list",
  [tokens.eventScreen.form.createForm.eventBreadcrumbsNavigationEdit]: "Event edit",

  [tokens.eventScreen.form.filters.searchByEvent]: "Search by event description",
  [tokens.eventScreen.form.filters.isFreeLabel]: "Free event only",

  [tokens.eventScreen.form.createForm.btnCreateEvent]: "Create Event",
  [tokens.eventScreen.form.createForm.btnCancelEvent]: "Cancel",
  [tokens.eventScreen.form.editForm.btnEditEvent]: "Update Event",
  [tokens.eventScreen.form.sucess_message]: "Event created/updated successfully",
  [tokens.eventScreen.form.error_message]: "An error occurred while persisting the data",

  //customer evet
  [tokens.customerEventScreen.main_title]: "Customer list",
  [tokens.customerEventScreen.table_main.header.name]: "Customer",
  [tokens.customerEventScreen.table_main.header.createdAt]: "Registered in",
  [tokens.customerEventScreen.cvBreadcrumbsNavigationList]: "Event list",
  [tokens.customerEventScreen.cvBreadcrumbsNavigationEdit]: "Customers",
  // Customer
  [tokens.customerScreen.main_title]: "Customer",
  [tokens.customerScreen.last_customer_details]: "Last registered customers",
  [tokens.customerScreen.customer_created_at]: "Joined: ",
  [tokens.customerScreen.table_main.header.id]: "Id",
  [tokens.customerScreen.table_main.header.customer]: "Customer",
  [tokens.customerScreen.table_main.header.createdAt]: "Created at",
  [tokens.customerScreen.btn_create_customer]: "Create customer",
  [tokens.customerScreen.form.createForm.create_title]: "New customer",
  [tokens.customerScreen.form.editForm.title]: "Update customer",
  [tokens.customerScreen.form.createForm.customer]: "customer",
  [tokens.customerScreen.form.createForm.customerDetails]: "Customer details",
  [tokens.customerScreen.form.createForm.shortDescription]: "Short description",
  [tokens.customerScreen.form.createForm.fullDescription]: "Full description",
  [tokens.customerScreen.form.createForm.btnCreateCustomer]: "Create customer",
  [tokens.customerScreen.form.createForm.btnCancelCustomer]: "Cancel",
  [tokens.customerScreen.form.editForm.btnEditCustomer]: "Update customer",
  [tokens.customerScreen.form.sucess_message]: "Customer created/updated successfully",
  [tokens.customerScreen.form.error_message]: "An error occurred while creating the new customer",
  [tokens.notification.title]: "New message",
  [tokens.notification.messageTypeDocument]: "New document sent",
  [tokens.notification.messageTypeImage]: "New image sent",
  [tokens.notification.locale]: "en_US",
  [tokens.notification.dashboard.title]: "Notifications",
  [tokens.notification.dashboard.not_found]: "There are no notifications",
  [tokens.customerScreen.main_title]: "Customers",
  [tokens.customerScreen.dashboard_customer_details]: "Dashboard: Customers Details",
  [tokens.customerScreen.import_label]: "Import",
  [tokens.customerScreen.export_label]: "Export",
  [tokens.customerScreen.customer_id]: "Customer id",
  [tokens.customerScreen.table_main.filters.search]: "Search customers by e-mail, name or city",
  [tokens.customerScreen.table_main.filters.sort_by_update_new]: "Last update (newest)",
  [tokens.customerScreen.table_main.filters.sort_by_update_last]: "Last update (oldest)",
  [tokens.customerScreen.table_main.filters.sort_by_total_asc]: "Total orders (highest)",
  [tokens.customerScreen.table_main.filters.sort_by_total_desc]: "Total orders (lowest)",
  [tokens.customerScreen.table_main.filters.filter_by_none]: "None",
  [tokens.customerScreen.table_main.filters.filter_by_firstName]: "FirstName",
  [tokens.customerScreen.table_main.filters.filter_by_lastName]: "LastName",
  [tokens.customerScreen.table_main.filters.filter_by_condominiumName]: "CondominiumName",
  [tokens.customerScreen.table_main.filters.filter_by_documentNumber]: "DocumentNumber",
  [tokens.customerScreen.table_main.filters.filter_by_customer_email]: "E-mail",
  [tokens.customerScreen.table_main.header.name]: "Name",
  [tokens.customerScreen.table_main.header.location]: "Location",
  [tokens.customerScreen.table_main.header.status]: "Status",
  [tokens.customerScreen.table_main.header.orders]: "Orders",
  [tokens.customerScreen.table_main.header.spent]: "Spent",
  [tokens.customerScreen.table_main.filters.all]: "All",
  [tokens.customerScreen.table_main.filters.pending_approval]: "Pending approval",
  [tokens.customerScreen.table_main.filters.accepts_markenting]: "Accepts Marketing",
  [tokens.customerScreen.details.customer_detail_column]: "Details",
  [tokens.customerScreen.details.customer_address_column]: "Address",
  [tokens.customerScreen.details.customer_contacts_column]: "Contacts",
  [tokens.customerScreen.details.customer_invoice_column]: "Invoices",
  [tokens.customerScreen.details.detail_title]: "Basic Details",
  [tokens.customerScreen.details.email_label]: "Email",
  [tokens.customerScreen.details.phone_label]: "Phone",
  [tokens.customerScreen.details.country_label]: "Country",
  [tokens.customerScreen.details.state_label]: "State",
  [tokens.customerScreen.details.address_label_1]: "Main address",
  [tokens.customerScreen.details.address_label_2]: "Secondary address",
  [tokens.customerScreen.details.condominium_label]: "Condominium",
  [tokens.customerScreen.details.btn_reset_password]: "Reset password",
  [tokens.customerScreen.address.address_title]: "Addresses",
  [tokens.customerScreen.address.address_table.id_column]: "ID",
  [tokens.customerScreen.address.address_table.street_name_column]: "Address",
  [tokens.customerScreen.address.address_table.neighborhood_column]: "Neighborhood",
  [tokens.customerScreen.address.address_table.zipcode_column]: "Zipcode",
  [tokens.customerScreen.address.address_table.state_column]: "State",
  [tokens.customerScreen.address.address_table.capital_column]: "Capital",
  [tokens.customerScreen.address.address_table.date_column]: "Creation date",
  [tokens.customerScreen.payments.payment_title]: "Invoices",
  [tokens.customerScreen.payments.payment_table.payment_id_column]: "Payment No.",
  [tokens.customerScreen.payments.payment_table.order_id_column]: "Order No.",
  [tokens.customerScreen.payments.payment_table.payment_date_column]: "Payment date",
  [tokens.customerScreen.payments.payment_table.created_date_column]: "Creation date",
  [tokens.customerScreen.payments.payment_table.gross_value_column]: "Gross value total",
  [tokens.customerScreen.payments.payment_table.net_value_column]: "Net value total",
  [tokens.customerScreen.payments.payment_table.fee_column]: "Fee total",
  [tokens.customerScreen.payments.payment_table.status_column]: "Status",
  [tokens.customerScreen.payments.payment_table.action_column]: "Actions",
  [tokens.customerScreen.payments.summary.summary_title]: "Financial Summary",
  [tokens.customerScreen.payments.summary.amount_orders]: "Amount Orders",
  [tokens.customerScreen.payments.summary.gross_value_total]: "Gross value total",
  [tokens.customerScreen.payments.summary.net_value_total]: "Net value total",
  [tokens.customerScreen.payments.summary.fee_total]: "Fee total",
  [tokens.customerScreen.payments.summary.last_order_date]: "Last order date",
  [tokens.customerScreen.payments.payment_paid]: "Paid",
  [tokens.customerScreen.payments.payment_pending]: "Pending",
  [tokens.customerScreen.email.email_title]: "Emails",
  [tokens.customerScreen.email.btn_send_mail]: "Send email",
  [tokens.customerScreen.email.options.resend_last_invoice]: "Resend last invoice",
  [tokens.customerScreen.email.options.send_password_reset]: "Send password reset",
  [tokens.customerScreen.email.options.send_verification]: "Send verification",
  [tokens.customerScreen.data_management.title]: "Data Management",
  [tokens.customerScreen.data_management.description]:
    "Remove this customer's data if they requested this. After the action, the customer will no longer have access to the app.",
  [tokens.customerScreen.data_management.btn_delete_account]: "Delete Account",
  [tokens.customerScreen.status_active]: "Active",
  [tokens.customerScreen.status_enabled]: "Enable",
  [tokens.customerScreen.form.first_name]: "First name",
  [tokens.customerScreen.form.last_name]: "Last name",
  [tokens.customerScreen.form.condominum_name]: "Condominium",
  [tokens.customerScreen.form.country_name]: "Country",
  [tokens.customerScreen.form.state_name]: "State",
  [tokens.customerScreen.form.city_name]: "City",
  [tokens.customerScreen.form.email_name]: "Email",
  [tokens.customerScreen.form.document_name]: "Document number",
  [tokens.customerScreen.form.birthday_name]: "Birthday Date",
  [tokens.customerScreen.form.phone_name]: "Phone number",
  [tokens.customerScreen.form.marital_status_name]: "Marital status",
  [tokens.customerScreen.form.gender_name]: "Gender",
  [tokens.customerScreen.form.customer_notes]: "Customer notes",
  [tokens.customerScreen.form.customer_notes_description]:
    "Record the interactions you have with the customer here",
  [tokens.customerScreen.form.created_date]: "Registration date",
  [tokens.customerScreen.form.updated_date]: "Last change",
  [tokens.customerScreen.form.customer_receive_contacts]: "Agree to receive contacts.",
  [tokens.customerScreen.form.customer_receive_contacts_description]:
    "The client may receive marketing contacts and surveys.",
  [tokens.customerScreen.form.customer_active]: "Active",
  [tokens.customerScreen.form.customer_active_description]:
    "The client has not yet been activated by the platform or is blocked.",
  [tokens.customerScreen.form.customer_not_register]: "Customer not registered.",
  [tokens.customerScreen.form.customer_not_register_description]:
    "Error registering customer on the gateway.",
  [tokens.customerScreen.form.btn_update_customer]: "Update customer",
  [tokens.customerScreen.form.btn_customer_cancel]: "Cancel",
  [tokens.customerScreen.form.edit_customer]: "Edit customer",
  [tokens.customerScreen.address.form.create_address]: "Add address",
  [tokens.customerScreen.address.form.address_create_title]: "New Address",
  [tokens.customerScreen.address.form.address_edit_title]: "Edit Address",
  [tokens.customerScreen.address.form.address_title]: "Address details",
  [tokens.customerScreen.address.form.address_id]: "Address code",
  [tokens.customerScreen.messages.customer_edit_error]: "Error when changing customer data!",
  [tokens.customerScreen.messages.customer_edit_success]: "Client updated successfully",
  [tokens.customerScreen.messages.address_error]: "Something went wrong!",
  [tokens.customerScreen.messages.address_create_success]: "Address created successfully",
  [tokens.customerScreen.messages.address_edit_success]: "Address edited successfully",
  [tokens.customerScreen.address.form.street_name_label]: "Street name",
  [tokens.customerScreen.address.form.number_label]: "Number",
  [tokens.customerScreen.address.form.neighborhood_label]: "Neighborhood",
  [tokens.customerScreen.address.form.complimentary_information_label]: "Additional information",
  [tokens.customerScreen.address.form.zipcode_label]: "ZipCode",
  [tokens.customerScreen.address.form.latitude_label]: "Latitude",
  [tokens.customerScreen.address.form.longitude_label]: "Longitude",
  [tokens.customerScreen.address.form.state_label]: "State",
  [tokens.customerScreen.address.form.country_label]: "Country",
  [tokens.customerScreen.address.form.city_label]: "City",
  [tokens.customerScreen.address.form.btn_edit_address]: "Update address",
  [tokens.customerScreen.address.form.btn_save_address]: "Save address",
  [tokens.customerScreen.address.modal.title]: "Deletion confirmation!",
  [tokens.customerScreen.address.modal.description]:
    "Attention! The following action is permanent. Do you want to delete the code address :id",
  [tokens.customerScreen.contacts.contact_tile]: "Contacts",
  [tokens.customerScreen.contacts.contact_table.contact_id_column]: "ID",
  [tokens.customerScreen.contacts.contact_table.phone_area_column]: "Phone area",
  [tokens.customerScreen.contacts.contact_table.phone_code_column]: "Phone code",
  [tokens.customerScreen.contacts.contact_table.phone_number_column]: "Phone number",
  [tokens.customerScreen.contacts.contact_table.talk_to_column]: "Talk to",
  [tokens.customerScreen.contacts.contact_table.email_column]: "Email",
  [tokens.customerScreen.contacts.contact_table.isMain_column]: "Main contact",
  [tokens.customerScreen.contacts.contact_table.createAt_column]: "Create at",
  [tokens.customerScreen.contacts.form.create_contact_title]: "Add contact",
  [tokens.customerScreen.contacts.form.contacts_create_title]: "New Contacts",
  [tokens.customerScreen.contacts.form.contacts_edit_title]: "Update Contacts",
  [tokens.customerScreen.contacts.form.contact_id]: "Contact code",
  [tokens.customerScreen.messages.contacts_error]: "Something went wrong!",
  [tokens.customerScreen.messages.contacts_create_success]: "Contact created successfully",
  [tokens.customerScreen.messages.contacts_edit_success]: "Contact edited successfully",
  [tokens.customerScreen.contacts.form.talk_to_label]: "Responsible",
  [tokens.customerScreen.contacts.form.phone_area_label]: "Phone area",
  [tokens.customerScreen.contacts.form.phone_code_label]: "Phone code",
  [tokens.customerScreen.contacts.form.phone_number_label]: "Phone number",
  [tokens.customerScreen.contacts.form.email_label]: "Email",
  [tokens.customerScreen.contacts.form.main_contact_label]: "Main contact",
  [tokens.customerScreen.contacts.form.main_contact_description]:
    "By choosing this option, you are designating this contact as one of the customer's primary contacts.",
  [tokens.customerScreen.contacts.form.btn_edit_contacts]: "Update Contact",
  [tokens.customerScreen.contacts.form.btn_save_contacts]: "Save Contact",
  [tokens.customerScreen.contacts.form.contact_tile]: "Contacts details",

  // Overview
  [tokens.overviewScreen.main_title]: "Overview",
  [tokens.overviewScreen.label_overview_period]: "Based on the selected period",
  [tokens.overviewScreen.customerSubscriptions.amount_customers]: "Customers",
  [tokens.overviewScreen.customerSubscriptions.labelcustomerSubscriptions]:
    "Customers subscriptions",
  [tokens.overviewScreen.customerSubscriptions.year]: "Year",
  [tokens.overviewScreen.customerSubscriptions.labelThisYear]: "This year",
  [tokens.overviewScreen.customerSubscriptions.labelLastYear]: "Last year",
  [tokens.overviewScreen.customerSubscriptions.months.jan]: "Jan",
  [tokens.overviewScreen.customerSubscriptions.months.feb]: "Feb",
  [tokens.overviewScreen.customerSubscriptions.months.mar]: "Mar",
  [tokens.overviewScreen.customerSubscriptions.months.apr]: "Apr",
  [tokens.overviewScreen.customerSubscriptions.months.may]: "May",
  [tokens.overviewScreen.customerSubscriptions.months.jun]: "Jun",
  [tokens.overviewScreen.customerSubscriptions.months.jul]: "Jul",
  [tokens.overviewScreen.customerSubscriptions.months.aug]: "Aug",
  [tokens.overviewScreen.customerSubscriptions.months.sep]: "Sep",
  [tokens.overviewScreen.customerSubscriptions.months.oct]: "Oct",
  [tokens.overviewScreen.customerSubscriptions.months.nov]: "Nov",
  [tokens.overviewScreen.customerSubscriptions.months.dec]: "Dec",

  [tokens.overviewScreen.latestTransactions.labelLastWeek]: "Last week",

  [tokens.overviewScreen.latestTransactions.labelLatestTransactions]: "Latest transactions",
  [tokens.customerScreen.contacts.modal.title]: "Deletion confirmation!",
  [tokens.customerScreen.contacts.modal.description]:
    "Attention! The following action is permanent. Do you want to delete the code contact :id",
}
